import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { baseSelect } from './joborder';
import { EMPTY } from 'rxjs';

export interface candidatemodel {
  list: any[];
  item: any | null;
  job_type: baseSelect[];
  referralType: baseSelect[];
  passportType: baseSelect[];
  fieldNameList: baseSelect[];

  marital_status: baseSelect[];
  passport_status: baseSelect[];
  visa_status: baseSelect[];
  qualification: baseSelect[];
  workExperience: baseSelect[];
  designationType: baseSelect[];
  noticePeriodType: baseSelect[];
  relationShipType: baseSelect[];

  intReferralType: baseSelect[];
  selectionStatus: baseSelect[];
  availableStatus: baseSelect[];

  interviewStatus: baseSelect[];
}

export class FetchCandidateList {
  static readonly type = '[FetchCandidateList] CandidateState';
}

export class FetchCandidateById {
  static readonly type = '[FetchCandidateById] CandidateState';
  constructor(public id: string) {}
}

@State<candidatemodel>({
  name: 'candidate',
  defaults: {
    list: [
      {
        candidate_id: '1',
        name: 'Arun Raj',
        entry_date: '20/05/2022',
        work_exp: '2 Years',
      },
    ],
    item: null,
    job_type: [
      {
        label: 'Blue Collar',
        value: '1',
      },
      {
        label: 'White Collar',
        value: '2',
      },
    ],
    referralType: [
      { label: 'Direct', value: '1' },
      { label: 'Associate', value: '2' },
    ],
    passportType: [
      { label: 'ECR', value: '1' },
      { label: 'ECNR', value: '2' },
    ],
    fieldNameList: [
      { label: 'Engineering', value: '1' },
      { label: 'Construction', value: '2' },
    ],
    marital_status: [
      {
        label: 'Single',
        value: '0',
      },
      {
        label: 'Married',
        value: '1',
      },
      {
        label: 'Divorced',
        value: '2',
      },
      {
        label: 'Complicated',
        value: '3',
      },
    ],
    passport_status: [
      { label: 'Unavailable', value: '0' },
      { label: 'Available', value: '1' },
    ],
    visa_status: [
      { label: 'Employment Visa', value: '1' },
      { label: 'Visit Visa', value: '2' },
      { label: 'Cancelled Visa', value: '3' },
      { label: 'Freelance Visa', value: '4' },
      { label: 'No Visa', value: '5' },
      { label: 'Mission Visa', value: '6' },
    ],
    qualification: [
      { value: '1', label: 'No Education' },
      { value: '2', label: 'Below 10th' },
      { value: '3', label: '10th' },
      { value: '4', label: '12th' },
      { value: '5', label: 'Diploma' },
      { value: '6', label: 'Graduation' },
      { value: '7', label: 'Post Graduation' },
      { value: '8', label: 'Professional Course' },
      { value: '9', label: 'Other Course' },
    ],
    workExperience: [
      {
        label: 'Fresher',
        value: '1',
      },
      {
        label: 'Experienced',
        value: '2',
      },
    ],
    designationType: [],
    noticePeriodType: [
      { label: 'Immediate Joiner', value: '0' },
      { label: 'Upto 1 Week', value: '1' },
      { label: 'Upto 15 Days', value: '2' },
      { label: 'Upto 30 Days', value: '3' },
      { label: 'Upto 45 Days', value: '4' },
      { label: 'Upto 60 Days', value: '5' },
      { label: 'Upto 90 Days', value: '6' },
    ],
    relationShipType: [
      { value: '0', label: 'Parent' },
      { value: '1', label: 'Sibling' },
      { value: '2', label: 'Spouse' },
      { value: '3', label: 'Relative' },
      { value: '4', label: 'Guardian' },
      { value: '5', label: 'Friend' },
    ],
    intReferralType: [
      { label: 'Direct', value: '1' },
      { label: 'Associate', value: '2' },
    ],
    selectionStatus: [
      { label: 'Shortlisted', value: '1' },
      { label: 'Shortlisted but kept Pending', value: '2' },
      { label: 'Not Shortlisted / Rejected', value: '3' },
    ],
    availableStatus: [],
    interviewStatus: [
      {
        label: 'Not Reviewed',
        value: '0',
      },
      {
        label: 'Reviewed',
        value: '1',
      },
    ],
  },
})
@Injectable()
export class CandidateState {
  constructor() {}

  @Selector()
  static getList(state: candidatemodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: candidatemodel): any {
    return state.item;
  }

  @Selector()
  static getJobType(state: candidatemodel): baseSelect[] {
    return state.job_type;
  }

  @Selector()
  static getFieldNameList(state: candidatemodel): baseSelect[] {
    return state.fieldNameList;
  }

  @Selector()
  static getReferralType(state: candidatemodel): baseSelect[] {
    return state.referralType;
  }

  @Selector()
  static getPassportType(state: candidatemodel): baseSelect[] {
    return state.passportType;
  }

  @Selector()
  static getMaritalStatus(state: candidatemodel): baseSelect[] {
    return state.marital_status;
  }

  @Selector()
  static getPassportStatus(state: candidatemodel): baseSelect[] {
    return state.passport_status;
  }

  @Selector()
  static getVisaStatus(state: candidatemodel): baseSelect[] {
    return state.visa_status;
  }

  @Selector()
  static getQualificationStatus(state: candidatemodel): baseSelect[] {
    return state.qualification;
  }

  @Selector()
  static getExpLevel(state: candidatemodel): baseSelect[] {
    return state.workExperience;
  }

  @Selector()
  static getDesignationType(state: candidatemodel): baseSelect[] {
    return state.designationType;
  }

  @Selector()
  static getNoticePeriodType(state: candidatemodel): baseSelect[] {
    return state.noticePeriodType;
  }

  @Selector()
  static getRelationShipType(state: candidatemodel): baseSelect[] {
    return state.relationShipType;
  }

  @Selector()
  static getIntReferralType(state: candidatemodel): baseSelect[] {
    return state.intReferralType;
  }

  @Selector()
  static getSelectionStatus(state: candidatemodel): baseSelect[] {
    return state.selectionStatus;
  }

  @Selector()
  static getavailableStatus(state: candidatemodel): baseSelect[] {
    return state.availableStatus;
  }

  @Selector()
  static getInterviewStatus(state: candidatemodel): baseSelect[] {
    return state.interviewStatus;
  }

  @Action(FetchCandidateList)
  fetchCandidateList(
    states: StateContext<candidatemodel>,
    action: FetchCandidateList
  ) {}

  @Action(FetchCandidateById)
  fetchCandidateById(
    states: StateContext<candidatemodel>,
    action: FetchCandidateById
  ) {
    let sample = [
      {
        job_type: '1',
        referral_type: '1',
        referred_by: '8754713794',
      },
      {
        name: 'Manoharan',
        contact_number: '9900229911',
        mobile_prefix: '101',
        whatsapp_number: '8754713794',
        dob: '2024-04-24',
        nationality: '101',
        address: '117, Packrisamy Pillai Street, Vadalur, Chidambaram',
        country: '101',
        state: '4035',
        city: '131517',
        pincode: '600002',
        height_in_cm: '175',
        height_in_inch: "5'4''",
        weight: '65',
        marital_status: '1',
        current_country: '101',
        current_state: '4035',
        current_city: '131517',
        photo: 'https://skoverseashrconsultancy.xyz/App/assets/report/17.jpg',
      },
      {
        passport_status: '1',
        name: 'Mugunthan',
        sur_name: 'Konda',
        passport_type: null,
        passport_number: 'J3435D4',
        passport_issue_date: '2024-04-16',
        passport_expiry_date: '2024-04-23',
        visa_status: '2',
        visa_remarks:
          "This tourist visa allows a single entry for a duration of 90 days. Holders are not permitted to engage in employment activities and must exit the country before the visa's expiry date. Extension of stay is not permitted. The visa is strictly for tourism purposes, allowing visitors to explore tourist attractions. Holders must comply with local laws and regulations during their stay. Failure to adhere to visa conditions may result in penalties or deportation. It is advisable to familiarize oneself with the visa terms and abide by them to ensure a smooth and enjoyable travel experience.",
        passport:
          'https://skoverseashrconsultancy.xyz/App/assets/report/19.jpg',
      },
      {
        qualification: '6',
        certification:
          'This is to certify that [Name] has successfully completed the [Course Name] training program with distinction. Throughout the course, [he/she] demonstrated exceptional dedication, perseverance, and a keen understanding of the subject matter. [His/Her] commitment to excellence and willingness to learn have been exemplary. This certificate is a testament to [his/her] hard work and academic achievement, reflecting [his/her] proficiency in [specific skills or knowledge areas]. We extend our warmest congratulations and wish [him/her] continued success in [his/her] future endeavors. Awarded on [Date].\n\n[Signature]\n\n[Issuer Name/Institution]\n\n',
        cv_resume:
          'https://skoverseashrconsultancy.xyz/App/assets/report/16.jpg',
        designation_applying_for: null,
        other_expertise: null,
        current_salary: '1000000',
        currency_type: 'INR',
        min_salary: '1200000',
        min_currency_type: 'INR',
        max_salary: '1800000',
        max_currency_type: 'INR',
        work_experience: '2',
        home_country_experience: '2',
        home_country_name: '101',
        gcc_gulf_experience: '3',
        gcc_gulf_country_name: '5',
        overseas_experience: '4',
        overseas_country_name: '4',
        total_experience: '0',
        current_employer: 'HTC',
        current_position: null,
        notice_period: '3',
        reference: [
          {
            name: 'ARunraj',
            contact_number: '8578021977',
            relationship: '5',
          },
        ],
      },
      {
        assigned_interview_date: '2024-04-24',
        attended_interview: '1',
        attended_interview_date: '2024-05-01',
      },
      {
        cv_received_on: '2024-04-25',
        candidate_available_status: null,
        selection_status: '1',
        position_selected_for: null,
        mode_of_selection: null,
        interview_date: '2024-04-25',
        interviewed_by: null,
        interview_feedback:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]",
        selection_remarks:
          "Subject: Mathematics\n\nRemarks:\n\nPerformance: The student consistently demonstrates a strong understanding of mathematical concepts and applies them effectively in problem-solving scenarios. Their ability to grasp complex topics quickly is commendable.\nParticipation: The student actively participates in class discussions and engages in collaborative activities. They demonstrate a willingness to help peers and contribute positively to group projects.\nAreas for Improvement: While the student excels in most areas, they could benefit from focusing on time management during exams to ensure they complete all questions within the allocated time.\nRecommendation: Overall, the student's performance in mathematics is impressive, and they show great potential for further growth. With continued dedication and practice, they will undoubtedly excel even further in the subject.",
        industry_type: null,
        date_of_deployment: '2024-05-01',
        country: '5',
        state: '4027',
        city: '57634',
        offer_letter_status: '1',
        offer_letter_received_date: '2024-04-24',
        signedoff_date: '2024-05-02',
        associate: null,
        doc_status: '1',
        check_status: '1',
        sk_comments:
          "Interview Comment:\n\nI had the pleasure of interviewing [Candidate's Name] for the [Position Title] role. Throughout the interview, I was impressed by [his/her] strong communication skills and ability to articulate ideas clearly. [He/She] demonstrated a deep understanding of the role and showcased relevant experiences that align well with our team's needs.\n\nOne aspect that stood out was [his/her] proactive approach to problem-solving, as evidenced by [specific example]. Additionally, [he/she] showed great enthusiasm for [specific aspect of the role or company], which was refreshing to see.\n\nOverall, I believe [Candidate's Name] would be a valuable addition to our team, bringing both skills and passion to the role. I look forward to seeing [him/her] contribute positively to our organization's success.",
        remarks:
          "Interview Comment:\n\nI had the pleasure of interviewing [Candidate's Name] for the [Position Title] role. Throughout the interview, I was impressed by [his/her] strong communication skills and ability to articulate ideas clearly. [He/She] demonstrated a deep understanding of the role and showcased relevant experiences that align well with our team's needs.\n\nOne aspect that stood out was [his/her] proactive approach to problem-solving, as evidenced by [specific example]. Additionally, [he/she] showed great enthusiasm for [specific aspect of the role or company], which was refreshing to see.\n\nOverall, I believe [Candidate's Name] would be a valuable addition to our team, bringing both skills and passion to the role. I look forward to seeing [him/her] contribute positively to our organization's success.",
        date_sent_to_client: '2024-04-26',
        ref_client_date: '2024-04-27',
        sent_to_approval_date: '2024-04-28',
        doc_rec_date: '2024-04-29',
        evaluation_sheet:
          'https://skoverseashrconsultancy.xyz/App/assets/report/3.Special Days(vijayadashmi).jpg',
        others: 'https://skoverseashrconsultancy.xyz/App/assets/report/16.jpg',
      },
      {
        offered_salary: '980000',
        offered_currency_type: 'ARS',
        basic_salary: '780000',
        basic_currency_type: 'AMD',
        other_benefits: [
          {
            type: '1',
            amount: '34000',
            currency_type: 'AOA',
            facility: null,
          },
          {
            type: '2',
            amount: null,
            currency_type: null,
            facility: 'Free Wifi connection',
          },
        ],
      },
      {
        general_comments:
          "General Comments:\n\nTeamwork and Collaboration: The team has demonstrated excellent collaboration and communication skills throughout the project. Each member actively contributed ideas, supported one another, and worked cohesively towards common goals.\nProblem-Solving Abilities: The team exhibited strong problem-solving abilities when faced with challenges. They approached issues analytically, brainstormed creative solutions, and adapted quickly to changes in project requirements.\nLeadership and Initiative: Several team members took on leadership roles and showed initiative in driving project progress. They effectively delegated tasks, motivated team members, and ensured deadlines were met.\nCommunication and Presentation Skills: The team effectively communicated project updates and findings to stakeholders through clear and concise presentations. They demonstrated professionalism and confidence in delivering their messages.\nAdaptability and Flexibility: Despite encountering unexpected obstacles, the team remained adaptable and flexible in adjusting their strategies and plans. They embraced changes positively and maintained a positive attitude throughout.\nAttention to Detail: The team consistently demonstrated attention to detail in their work, ensuring accuracy and quality in all deliverables. They were thorough in reviewing and refining their outputs to meet high standards.\nOverall Performance: Overall, the team's performance has been commendable. They have showcased professionalism, dedication, and a strong work ethic, contributing significantly to the success of the project.",
        commented_by: null,
        galfar_or_other: '8754713794',
        date_sent_to_gm: '2024-04-25',
        feedback_date_from_gm: '2024-04-26',
        feedback: '1',
        feedback_details:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        date_sent_to_client: '2024-04-25',
        feedback_from_client: '2024-04-26',
        client_feedback: '1',
        client_feedback_details:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        no_of_days_taken: '1 Days',
      },
      {
        visa_processing_batch_ref:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        doc_sent_date: '2024-04-18',
        visa_received_date: '2024-05-03',
        visa_issue_date: '2024-05-21',
        visa_expiry_date: '2024-05-23',
        visa_sent_date: '2024-05-03',
        ticket_req_receive_date: '2024-04-18',
        ticket_req_sent_date: '2024-04-26',
        ticket_receive_date: '2024-05-01',
        ticket_sent_to_associate_date: '2024-05-02',
        assigned_travel_date: '2024-04-25',
        last_date_of_visa_change: '2024-05-02',
        airlines: null,
        booking_ref:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        travel_letter_date: '2024-04-17',
        return_ticket_date: '2024-04-12',
        candidate_traveled: '1',
        departure_airport: null,
        departure_date: '2024-04-26',
        departure_time: '2024-04-20',
        arrival_airport: null,
        arrival_date: '2024-04-30',
        arrival_time: '2024-04-30',
        joining_date: '2024-04-26',
        return_back: '1',
        cand_travel_date: '2024-04-25',
        remarks:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        candidate_status: null,
        undertaking_date: '2024-04-26',
        sk_remarks:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        pp_rec_date: '2024-04-18',
        photo_pp_rec_date: '2024-04-26',
        pcc_rec_date: '2024-05-03',
        eng_bio_rec_date: '2024-05-02',
        under_arabic_bio_rec_date: '2024-05-10',
        arabic_bio_rec_date: '2024-05-07',
        norm_form_date: '2024-05-11',
        norm_form:
          'https://skoverseashrconsultancy.xyz/App/assets/report/23.jpg',
        visa_doc_sent_date: '2024-04-12',
        medical_fit: '1',
      },
      {
        reason_to_leave:
          "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
        interview_evaluation_point_of_the_worker: '8',
      },
    ];

    states.patchState({
      item: {
        candidate_initate: {
          job_type: '1',
          referral_type: '1',
          referred_by: '8754713794',
        },
        personal_profile: {
          name: 'Manoharan',
          contact_number: '9900229911',
          mobile_prefix: '101',
          whatsapp_number: '8754713794',
          dob: '2024-04-24',
          nationality: '101',
          address: '117, Packrisamy Pillai Street, Vadalur, Chidambaram',
          country: '101',
          state: '4035',
          city: '131517',
          pincode: '600002',
          height_in_cm: '175',
          height_in_inch: "5'4''",
          weight: '65',
          marital_status: '1',
          current_country: '101',
          current_state: '4035',
          current_city: '131517',
          photo: 'https://skoverseashrconsultancy.xyz/App/assets/report/17.jpg',
        },
        passport_info: {
          passport_status: '1',
          name: 'Mugunthan',
          sur_name: 'Konda',
          passport_type: null,
          passport_number: 'J3435D4',
          passport_issue_date: '2024-04-16',
          passport_expiry_date: '2024-04-23',
          visa_status: '2',
          visa_remarks:
            "This tourist visa allows a single entry for a duration of 90 days. Holders are not permitted to engage in employment activities and must exit the country before the visa's expiry date. Extension of stay is not permitted. The visa is strictly for tourism purposes, allowing visitors to explore tourist attractions. Holders must comply with local laws and regulations during their stay. Failure to adhere to visa conditions may result in penalties or deportation. It is advisable to familiarize oneself with the visa terms and abide by them to ensure a smooth and enjoyable travel experience.",
          passport:
            'https://skoverseashrconsultancy.xyz/App/assets/report/19.jpg',
        },
        work_profile: {
          qualification: '6',
          certification:
            'This is to certify that [Name] has successfully completed the [Course Name] training program with distinction. Throughout the course, [he/she] demonstrated exceptional dedication, perseverance, and a keen understanding of the subject matter. [His/Her] commitment to excellence and willingness to learn have been exemplary. This certificate is a testament to [his/her] hard work and academic achievement, reflecting [his/her] proficiency in [specific skills or knowledge areas]. We extend our warmest congratulations and wish [him/her] continued success in [his/her] future endeavors. Awarded on [Date].\n\n[Signature]\n\n[Issuer Name/Institution]\n\n',
          cv_resume:
            'https://skoverseashrconsultancy.xyz/App/assets/report/16.jpg',
          designation_applying_for: null,
          other_expertise: null,
          current_salary: '1000000',
          currency_type: 'INR',
          min_salary: '1200000',
          min_currency_type: 'INR',
          max_salary: '1800000',
          max_currency_type: 'INR',
          work_experience: '2',
          home_country_experience: '2',
          home_country_name: '101',
          gcc_gulf_experience: '3',
          gcc_gulf_country_name: '5',
          overseas_experience: '4',
          overseas_country_name: '4',
          total_experience: '0',
          current_employer: 'HTC',
          current_position: null,
          notice_period: '3',
          reference: [
            {
              name: 'ARunraj',
              contact_number: '8578021977',
              relationship: '5',
            },
          ],
        },
        interview_process: {
          assigned_interview_date: '2024-04-24',
          attended_interview: '1',
          attended_interview_date: '2024-05-01',
        },
        selection_process: {
          cv_received_on: '2024-04-25',
          candidate_available_status: null,
          selection_status: '1',
          position_selected_for: null,
          mode_of_selection: null,
          interview_date: '2024-04-25',
          interviewed_by: null,
          interview_feedback:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]",
          selection_remarks:
            "Subject: Mathematics\n\nRemarks:\n\nPerformance: The student consistently demonstrates a strong understanding of mathematical concepts and applies them effectively in problem-solving scenarios. Their ability to grasp complex topics quickly is commendable.\nParticipation: The student actively participates in class discussions and engages in collaborative activities. They demonstrate a willingness to help peers and contribute positively to group projects.\nAreas for Improvement: While the student excels in most areas, they could benefit from focusing on time management during exams to ensure they complete all questions within the allocated time.\nRecommendation: Overall, the student's performance in mathematics is impressive, and they show great potential for further growth. With continued dedication and practice, they will undoubtedly excel even further in the subject.",
          industry_type: null,
          date_of_deployment: '2024-05-01',
          country: '5',
          state: '4027',
          city: '57634',
          offer_letter_status: '1',
          offer_letter_received_date: '2024-04-24',
          signedoff_date: '2024-05-02',
          associate: null,
          doc_status: '1',
          check_status: '1',
          sk_comments:
            "Interview Comment:\n\nI had the pleasure of interviewing [Candidate's Name] for the [Position Title] role. Throughout the interview, I was impressed by [his/her] strong communication skills and ability to articulate ideas clearly. [He/She] demonstrated a deep understanding of the role and showcased relevant experiences that align well with our team's needs.\n\nOne aspect that stood out was [his/her] proactive approach to problem-solving, as evidenced by [specific example]. Additionally, [he/she] showed great enthusiasm for [specific aspect of the role or company], which was refreshing to see.\n\nOverall, I believe [Candidate's Name] would be a valuable addition to our team, bringing both skills and passion to the role. I look forward to seeing [him/her] contribute positively to our organization's success.",
          remarks:
            "Interview Comment:\n\nI had the pleasure of interviewing [Candidate's Name] for the [Position Title] role. Throughout the interview, I was impressed by [his/her] strong communication skills and ability to articulate ideas clearly. [He/She] demonstrated a deep understanding of the role and showcased relevant experiences that align well with our team's needs.\n\nOne aspect that stood out was [his/her] proactive approach to problem-solving, as evidenced by [specific example]. Additionally, [he/she] showed great enthusiasm for [specific aspect of the role or company], which was refreshing to see.\n\nOverall, I believe [Candidate's Name] would be a valuable addition to our team, bringing both skills and passion to the role. I look forward to seeing [him/her] contribute positively to our organization's success.",
          date_sent_to_client: '2024-04-26',
          ref_client_date: '2024-04-27',
          sent_to_approval_date: '2024-04-28',
          doc_rec_date: '2024-04-29',
          evaluation_sheet:
            'https://skoverseashrconsultancy.xyz/App/assets/report/3.Special Days(vijayadashmi).jpg',
          others:
            'https://skoverseashrconsultancy.xyz/App/assets/report/16.jpg',
        },
        salary_process: {
          offered_salary: '980000',
          offered_currency_type: 'ARS',
          basic_salary: '780000',
          basic_currency_type: 'AMD',
          other_benefits: [
            {
              type: '1',
              amount: '34000',
              currency_type: 'AOA',
              facility: null,
            },
            {
              type: '2',
              amount: null,
              currency_type: null,
              facility: 'Free Wifi connection',
            },
          ],
        },
        interview_feedback: {
          general_comments:
            "General Comments:\n\nTeamwork and Collaboration: The team has demonstrated excellent collaboration and communication skills throughout the project. Each member actively contributed ideas, supported one another, and worked cohesively towards common goals.\nProblem-Solving Abilities: The team exhibited strong problem-solving abilities when faced with challenges. They approached issues analytically, brainstormed creative solutions, and adapted quickly to changes in project requirements.\nLeadership and Initiative: Several team members took on leadership roles and showed initiative in driving project progress. They effectively delegated tasks, motivated team members, and ensured deadlines were met.\nCommunication and Presentation Skills: The team effectively communicated project updates and findings to stakeholders through clear and concise presentations. They demonstrated professionalism and confidence in delivering their messages.\nAdaptability and Flexibility: Despite encountering unexpected obstacles, the team remained adaptable and flexible in adjusting their strategies and plans. They embraced changes positively and maintained a positive attitude throughout.\nAttention to Detail: The team consistently demonstrated attention to detail in their work, ensuring accuracy and quality in all deliverables. They were thorough in reviewing and refining their outputs to meet high standards.\nOverall Performance: Overall, the team's performance has been commendable. They have showcased professionalism, dedication, and a strong work ethic, contributing significantly to the success of the project.",
          commented_by: null,
          galfar_or_other: '8754713794',
          date_sent_to_gm: '2024-04-25',
          feedback_date_from_gm: '2024-04-26',
          feedback: '1',
          feedback_details:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          date_sent_to_client: '2024-04-25',
          feedback_from_client: '2024-04-26',
          client_feedback: '1',
          client_feedback_details:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          no_of_days_taken: '1 Days',
        },
        visa_process: {
          visa_processing_batch_ref:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          doc_sent_date: '2024-04-18',
          visa_received_date: '2024-05-03',
          visa_issue_date: '2024-05-21',
          visa_expiry_date: '2024-05-23',
          visa_sent_date: '2024-05-03',
          ticket_req_receive_date: '2024-04-18',
          ticket_req_sent_date: '2024-04-26',
          ticket_receive_date: '2024-05-01',
          ticket_sent_to_associate_date: '2024-05-02',
          assigned_travel_date: '2024-04-25',
          last_date_of_visa_change: '2024-05-02',
          airlines: null,
          booking_ref:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          travel_letter_date: '2024-04-17',
          return_ticket_date: '2024-04-12',
          candidate_traveled: '1',
          departure_airport: null,
          departure_date: '2024-04-26',
          departure_time: '2024-04-20',
          arrival_airport: null,
          arrival_date: '2024-04-30',
          arrival_time: '2024-04-30',
          joining_date: '2024-04-26',
          return_back: '1',
          cand_travel_date: '2024-04-25',
          remarks:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          candidate_status: null,
          undertaking_date: '2024-04-26',
          sk_remarks:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          pp_rec_date: '2024-04-18',
          photo_pp_rec_date: '2024-04-26',
          pcc_rec_date: '2024-05-03',
          eng_bio_rec_date: '2024-05-02',
          under_arabic_bio_rec_date: '2024-05-10',
          arabic_bio_rec_date: '2024-05-07',
          norm_form_date: '2024-05-11',
          norm_form:
            'https://skoverseashrconsultancy.xyz/App/assets/report/23.jpg',
          visa_doc_sent_date: '2024-04-12',
          medical_fit: '1',
        },
        post_interview: {
          reason_to_leave:
            "Interview Feedback\n\nCandidate Name: [Candidate's Name]\nPosition Applied for: [Position Title]\nInterview Date: [Date of Interview]\nInterviewers: [Names of Interviewers]\n\nStrengths:\n\n[Candidate demonstrated excellent communication skills and articulately conveyed their thoughts.]\n[Strong technical knowledge and expertise in [specific area].]\n[Demonstrated ability to work well in a team and collaborate effectively.]\nAreas for Improvement:\n\n[Candidate could provide more specific examples during behavioral questions to demonstrate past experiences and achievements.]\n[Could further enhance knowledge in [specific area] to align with the requirements of the position.]\n[Should focus on maintaining eye contact and body language throughout the interview.]\nOverall Impression:\n[Candidate showed great potential and enthusiasm for the role. With some refinement in certain areas, they would be a strong fit for the team.]\n\nRecommendation:\n[Recommend moving forward to the next stage of the hiring process.]\n\n",
          interview_evaluation_point_of_the_worker: '8',
        },
      },
    });

    return EMPTY;
  }
}
